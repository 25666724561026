
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Lato:300);
@import "variables";

h1, h2, h3, h4, h5, h6
{
  font-family: Raleway;
}

h1
{
  font-size: 26px;
  margin-top: 6px;
  margin-bottom: 6px;
}

body
{
  background-image: url('../img/bg2.jpg');
  background-size: cover;
  font-family: Lato;
  font-size: 17px;

}

.navbar-brand
{
  padding-top: 2px;
  font-weight: bold;
  font-size: 18px;
  img
  {
    height: 45px;
    display: inline-block;
    padding-right: 3px;
  }
}

.matiere-commande-tableau
{
  margin-bottom: 12px;
  // height: 500px;
  // overflow-y: scroll;
  font-size: 12px;
  table
  {
    width: 100%;
  }
  h2
  {
    font-size: 22px;
  }
}

.production-tables-container
{
  overflow-x: auto;
  min-height: 280px;
  padding-bottom: 18px;
  table
  {
    font-size: 15px;
    padding: 3px 6px;
  }
  th
  {
    font-size: 15px !important;
  }
  .dataTables_paginate
  {
    transform: scale(0.7);
  }
  .production-matiere-commande-table
  {
    padding: 0;
  }
}

.commande-production
{
  color: #5BC115;
}

.commande-production-urgent
{
  color: #F00;
}


.commande-production-passee
{
  color: #F90;
}

// Désolé maman j'avais pas le choix
#commande-table_filter
{
  display: none;
}

.commande-liste-buttons
{
  min-width: 68px;
}


#printable-modal-dialog
{
  width: 99%;
  margin: 96px 15px 0 15px;
  table
  {
    font-size: 12px;
  }
}


.alert-flash
{
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: .3s;
  animation-delay: .3s;
  animation-fill-mode: forwards;
}

.alert-flash--closed
{
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.clear
{
  clear: both;
}

.clearfix
{
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}